import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import '../assets/css/product.css'
const ProductListItem = ({ src, title, caption, altText }) => {
  return (
      <Stack
        sx={{
          height: "100%",
          width: "16rem",
          marginRight: "1.5rem",
          flexShrink: "0",
          bgcolor: "#fff",
        }}
      >
        <img
          src={src}
          alt={altText}
          style={{
            display: "block",
            width: "100%",
            height: "100%",
            maxHeight: "8rem",
            objectFit: "cover",
          }}
        />
         <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            paddingLeft: "1rem",
            fontSize: "1.084rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#141527",
            marginBottom: "0.3rem",
            marginTop: "0.5rem",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textTransform: "none",
            width: "95%",
            paddingLeft: "1rem",
            textAlign: "left",
            fontSize: "0.9rem",
            lineHeight: "1.2",
            fontFamily: "Jost",
            fontWeight: "400",
            color: "#141527",
          }}
        >
          {caption}
        </Typography>
       
        
        
      </Stack>
    
  
  );
};

export default ProductListItem;
