// eslint-disable-next-line import/no-anonymous-default-export
export default {
    newsArray: [
      {
        src: "../assets/company-logo-forbes-black.png",
        altText: "forbes",
        title: "Forbes",
        caption: "The best way to avoid data leaks and privacy scnadals: Don't own consumer data",
        articleLink: "https://www.forbes.com/sites/joetoscano1/2019/04/24/the-best-way-to-avoid-data-leaks-and-privacy-scandals-dont-own-consumer-data/"
      },
      {
        src: "../assets/company-logo-techtarget-color.png",
        altText: "tech target",
        title: "Tech Target",
        caption: "Federated deep learning offers new approach to model training",
        articleLink: "https://www.techtarget.com/searchenterpriseai/feature/Federated-deep-learning-offers-new-approach-to-model-training"
      },
      {
        src: "../assets/company-logo-enterpriseai-color.png",
        altText: "enterprise AI",
        title: "Enterprise AI",
        caption: "Emerging AI business model promotes distributed ML",
        articleLink: "https://www.enterpriseai.news/2019/12/06/emerging-ai-business-model-promotes-distributed-ml/"
      }
    ],
  };
  