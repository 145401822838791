import { Stack, Typography, Box } from "@mui/material";
import { width } from "@mui/system";
import React from "react";
import '../assets/css/setup.css'
const SetupListItem = ({ src, title, caption, idx }) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "15rem",
        marginRight: "1.5rem",
        flexShrink: "0",
        bgcolor: "#141527",
      }}
      className="setup-box-stack"
    >

   
     
      <Box className="setup-box" sx={{
        width: '4rem',
        height: '4rem',
        borderRadius: '50%',
        backgroundColor: "#fff",
        marginBottom: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        margin:"1rem",
       


      }}
      >
         <Box sx={{
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
            backgroundColor: "#4BA7FC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            position: "absolute",
            top: "-15%",
            left: "-5%"


          }}
          >
            {idx+1}
          </Box>
          {src}
      </Box>
      <Typography
        sx={{
          textTransform: "none",
          width: "95%",
          textAlign: "left",
          fontSize: "0.9rem",
          lineHeight: "1.2",
          fontFamily: "Jost",
          fontWeight: "400",
          color: "#fff",
        }}
      >
        {caption}
      </Typography>
    </Stack>
  );
};

export default SetupListItem;
