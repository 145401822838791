import { Typography } from "@mui/material";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: (
    <Typography
      sx={{
        fontFamily: "Jost",
        fontWeight: "800",
        color: "#090A14",
        fontSize: {
          xl: "3rem",
          lg: "3rem",
          md: "3rem",
          sm: "2rem",
          xs: "1.5rem",
        },
        width: {
          xl: "50%",
          lg: "50%",
          md: "70%",
          sm: "100%",
          xs: "100%",
        },
      }}
    >
      {/* //! title text of Home page */}
      Unlocking $200B from World's <br/>
      6 Billion Cameras<br/>
      using a Low-Code Computer Vision Platform
    </Typography>
  ),
  caption: (
    <Typography
      sx={{
        textTransform: "none",
        textAlign: "center",
        fontSize: {
          xl: "1.5rem",
          lg: "1.3rem",
          md: "1.2rem",
          sm: "1.1rem",
          xs: "1.1rem",
        },
        fontFamily: "Jost",
        fontWeight: "500",
        padding: "1rem 0.5rem",
        color: "#fff",
      }}
    >
      {/* //! caption text of Home page */}
      Drive significant business outcome from video and image analysis
      <br />
      using state-of-the-art computer vision tools
    </Typography>
  ),
};
