import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import SolutionListItem from "../components/SolutionListItem";

import Data from "../Data/Solutions";
import '../assets/css/solutions.css';

const generateSolutionCards = () =>
  Data.solutionsArray.map((solution) => (
    <SolutionListItem {...solution} key={solution.title} />
  ));

const Solutions = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
      }}
      id="solutions"
    >
      <Stack
        alignItems="flex-start"
        justifyContent="space-evenly"
        sx={{
          marginLeft: "4rem",
          width: "100vw",
          minHeight: "50%",
          maxHeight: "70%",
          bgcolor: "#fff",
          paddingLeft: "5rem",
          paddingBottom: "6rem"
        }}
        className="solutions-stack"
      >
        <Box sx={{ width: "30%", height: "0.3rem", bgcolor: "#141527" }} />
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "1.8rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#141527",
            margin: "1rem 0",
          }}
        >
          Solutions
        </Typography>
        <Box
          sx={{
            height: "80%",
            width: "100%",
            overflowX: "auto",
            display: "flex",
          }}
        >
          {generateSolutionCards()}
        </Box>
      </Stack>
      <Stack
        direction={{
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        }}
        alignItems="center"
        justifyContent="space-evenly"
        sx={{
          width: "100%",
          height: "50%",
          "& p": {
            fontFamily: "Jost",
          },
        }}
      >
        <Stack
          sx={{
            width: {
              xl: "40%",
              lg: "40%",
              md: "40%",
              sm: "60%",
              xs: "60%",
            },
            height: "100%",
          }}
          alignItems="center"
          justifyContent="center"
          className="solutions-stats-stack"
        >
          <Typography
            sx={{
              textTransform: "none",
              textAlign: "left",
              fontSize: {
                xl: "1.5rem",
                lg: "1.084rem",
                md: "1.2rem",
                sm: "1.1rem",
                xs: "1rem",
              },
              fontFamily: "Jost",
              fontWeight: "400",
              color: "#fff",
            }}
          >
            {Data.solutionsPreamble}
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: {
              xl: "30%",
              lg: "30%",
              md: "30%",
              sm: "80%",
              xs: "80%",
            },
            height: "50%",
          }}
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: "100%", height: "40%" }}
          >
            <Box sx={{
                width: "10rem"
              }}>
              <Typography
                sx={{ fontWeight: "700", color: "#fff", fontSize: "2rem" }}
              >
                {Data.solutionsStats[0].value}
              </Typography>
              <Typography
                sx={{ fontWeight: "500", color: "#fff", fontSize: "1rem" }}
              >
                {Data.solutionsStats[0].title}
              </Typography>
            </Box>
            <Box sx={{
                width: "10rem"
              }}>
              <Typography
                sx={{ fontWeight: "700", color: "#fff", fontSize: "2rem" }}
              >
                {Data.solutionsStats[1].value}
              </Typography>
              <Typography
                sx={{ fontWeight: "500", color: "#fff", fontSize: "1rem" }}
              >
                {Data.solutionsStats[1].title}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: "100%", height: "40%" }}
          >
            <Box sx={{
              width: "10rem"
            }}>
              <Typography
                sx={{ fontWeight: "700", color: "#fff", fontSize: "2rem" }}
              >
                {Data.solutionsStats[2].value}
              </Typography>
              <Typography
                sx={{ fontWeight: "500", color: "#fff", fontSize: "1rem" }}
              >
                {Data.solutionsStats[2].title}
              </Typography>
            </Box>
            <Box sx={{
                width: "10rem"
              }}>
              <Typography
                sx={{ fontWeight: "700", color: "#fff", fontSize: "2rem" }}
              >
                {Data.solutionsStats[3].value}
              </Typography>
              <Typography
                sx={{ fontWeight: "500", color: "#fff", fontSize: "1rem" }}
              >
                {Data.solutionsStats[3].title}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          marginTop: "0.5rem",
          height: "5vh",
          width: "100%",
        }}
      >
        <Box sx={{ bgcolor: "#141527", height: "100%", width: "100%" }} />
        <Box sx={{ width: "4rem" }} />
      </Stack>
    </Box>
  );
};

export default Solutions;
