import { Stack, Typography, Box } from "@mui/material";
import { width } from "@mui/system";
import React from "react";

const FeatureListItem = ({ src, title, caption }) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "15rem",
        marginRight: "1.5rem",
        flexShrink: "0",
        bgcolor: "#fff",
      }}
    >
      
      <Box sx={{
        width: '4rem',
        height: '4rem',
        borderRadius: '50%',
        backgroundColor: "#141527",
        marginBottom: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"


      }}
      >
          {src}
      </Box>
      <Typography
        sx={{
          textTransform: "none",
          textAlign: "left",
          fontSize: "1.084rem",
          fontFamily: "Jost",
          fontWeight: "600",
          color: "#141527",
          marginBottom: "0.3rem",
          marginTop: "0.5rem"
        }}
      >
        {title}
      </Typography>
      {/* <Typography
        sx={{
          textTransform: "none",
          width: "95%",
          textAlign: "left",
          fontSize: "0.9rem",
          lineHeight: "1.2",
          fontFamily: "Jost",
          fontWeight: "400",
          color: "#141527",
        }}
      >
        {caption}
      </Typography> */}
    </Stack>
  );
};

export default FeatureListItem;
