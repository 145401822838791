import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import '../assets/css/news.css'

const NewsListItem = ({ src, title, caption, articleLink, altText }) => {
  return (
      <Stack
        sx={{
          height: "100%",
          width: "15rem",
          marginRight: "1.5rem",
          flexShrink: "0",
          bgcolor: "#fff",
        }}
      >
        <img
          src={src}
          alt={altText}
          style={{
            display: "block",
            width: "100%",
            height: "100%",
            maxHeight: "8rem",
            objectFit: "contain"
          }}
        />
         {/* <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "1.084rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#141527",
            marginBottom: "0.3rem",
            paddingTop:"1rem"
          }}
        >
          {title}
        </Typography> */}
        <Typography
          sx={{
            textTransform: "none",
            width: "95%",
            textAlign: "left",
            fontSize: "0.9rem",
            lineHeight: "1.2",
            fontFamily: "Jost",
            fontWeight: "400",
            color: "#141527",
          }}
        >
          {caption}
        </Typography>
        <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.75rem",
              lineHeight: "1.2",
              fontFamily: "Jost",
              fontWeight: "400",
              color: "#141527",
              paddingTop:"1rem"
            }}>
            <a href={articleLink}>Read article</a>
        </Typography>
       
        
        
      </Stack>
    
  
  );
};

export default NewsListItem;
