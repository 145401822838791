// eslint-disable-next-line import/no-anonymous-default-export
export default {
  solutionsArray: [
    {
      src: "../assets/people_counting.png",
      altText: "People Counting",
      title: "People Counting",
      caption: "Automatically count people without duplication at shops, factories, classes",
    },
    {
      src: "../assets/mask_detection.png",
      altText: "Safety & Compliance",
      title: "Safety & Compliance",
      caption: "Tracking and monitoring compliance rates across organisations",
    },
    {
      src: "../assets/image_enhancement.png",
      altText: "Super Resolution",
      title: "Super Resolution",
      caption: "Enhance the quality of images for better customer interactions",
    },
   
    {
      src: "../assets/car-part-detection.jpeg",
      altText: "Vehicle Part Detection",
      title: "Vehicle Part Detection",
      caption: "Detect car parts to scan for imperfections across various car types",
    },
    {
      src: "https://safecam-apps.s3.ap-south-1.amazonaws.com/app_smart-logistics-management.jpg",
      altText: "Smart Logistics Management",
      title: "Smart Logistics Management",
      caption: "Manages, counts and analyses items on conveyor belt pipelines",
    },
  ],
  solutionsPreamble:
    "Our core platform is based on several patent pending edge computing and federated learning technologies, driving significant growth",
  solutionsStats: [
    {
      title: "Images Processed",
      value: "15 billion",
    },
    {
      title: "Apps",
      value: "12+",
    },
    {
      title: "CV Models",
      value: "20",
    },
    {
      title: "Industries",
      value: "4+",
    },
  ],
};
