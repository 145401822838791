import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import NewsListItem from "../components/NewsListItem";
import '../assets/css/news.css'
import Data from "../Data/News";

const generateNewsCards = () =>
  Data.newsArray.map((news) => (
    <NewsListItem {...news} key={news.title} />
  ));

const News = () => {
  return (
    <Box
    sx={{
      width: "100vw",
      height: "60vh",
      paddingTop: "3rem"
    }}
    id="news"
    >
       <Stack
        alignItems="flex-start"
        justifyContent="space-evenly"
        sx={{
          height:"100%",
          bgcolor: "#fff",
          paddingTop: "3rem",
          paddingBottom: "3rem",
          paddingLeft: "5rem",
          marginLeft: "4rem"
        }}
      >
         <Box sx={{ width: "30%", height: "0.3rem", bgcolor: "#141527" }} />
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "1.8rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#141527",
            margin: "2rem 0",
          }}
        >
          News & Resources
        </Typography>

        <Stack
          sx={{
            width: "100%",
            height: "100%"
          }}
          direction="row"
          justifyContent={"space-evenly"}
          className="news-stack"
        >
          {generateNewsCards()}
        </Stack>
       
      </Stack>
      
     
    </Box>
  );
};

export default News;
