import { Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import Logo from '../components/Logo';
import '../assets/css/footer.css'


const Footer = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100%",
        backgroundColor: "#fff",
        
      }}
      id="footer"
    >
      {/* <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          height: "100%",
          padding: "3rem 4rem"
      }} className="footer-container">
          
                  <Box sx={{
                      flex:1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around"
                  }}
                  className="footer-company-info">
                            <Logo objectPosition={"left"}/>
                            <Typography
                              sx={{
                                textTransform: "none",
                                textAlign: "left",
                                fontSize: "0.9rem",
                                lineHeight: "1.2",
                                fontFamily: "Jost",
                                fontWeight: "400",
                                color: "#141527",
                                paddingTop: "1rem",
                                width: "50%"
                              }}
                              className="footer-company-text"
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque cursus euismod ultricies sed. Aliquam eu libero, praesent.
                            </Typography>
                            <Typography
                                sx={{
                                  textAlign: "left",
                                  fontSize: "0.75rem",
                                  lineHeight: "1.2",
                                  fontFamily: "Jost",
                                  fontWeight: "400",
                                  color: "#141527",
                                  paddingTop:"1rem"
                                }}>
                                <a href="mailto:origin@s20.ai" target="_blank">Contact Us</a>
                            </Typography>
                  </Box>



                  <Box sx={{
                          flex:1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start"
                      }}
                  >
                            <Box sx={{
                                display:"flex",
                                flexDirection: "row",
                                width: "75%",
                                justifyContent: "space-around"

                            }}
                            className="footer-links"
                            >
                                <Box>
                                <Typography
                                  sx={{
                                    textTransform: "none",
                                    textAlign: "left",
                                    fontSize: "1.084rem",
                                    fontFamily: "Jost",
                                    fontWeight: "600",
                                    color: "#141527",
                                    marginBottom: "0.3rem",
                                    paddingTop:"1rem",
                                    paddingBottom: "2rem"
                                  }}
                                >
                                 <a href="#landing">Overview</a>
                                </Typography></Box>
                                <Box>
                                          <Typography
                                            sx={{
                                              textTransform: "none",
                                              textAlign: "left",
                                              fontSize: "1.084rem",
                                              fontFamily: "Jost",
                                              fontWeight: "600",
                                              color: "#141527",
                                              marginBottom: "0.3rem",
                                              paddingTop:"1rem",
                                              paddingBottom: "2rem"
                                            }}
                                          >
                                           <a href="#products">Products</a>
                                          </Typography>
                                        
                                </Box>


                               <Box>
                                          <Typography
                                            sx={{
                                              textTransform: "none",
                                              textAlign: "left",
                                              fontSize: "1.084rem",
                                              fontFamily: "Jost",
                                              fontWeight: "600",
                                              color: "#141527",
                                              marginBottom: "0.3rem",
                                              paddingTop:"1rem",
                                              paddingBottom: "2rem"
                                            }}
                                          >
                                            <a href="#solutions">Solutions</a>
                                          </Typography>
                                         
                                </Box>


                                <Box>
                                          <Typography
                                            sx={{
                                              textTransform: "none",
                                              textAlign: "left",
                                              fontSize: "1.084rem",
                                              fontFamily: "Jost",
                                              fontWeight: "600",
                                              color: "#141527",
                                              marginBottom: "0.3rem",
                                              paddingTop:"1rem",
                                              paddingBottom: "2rem"
                                            }}
                                          >
                                            <a href="#features">Features</a>
                                          </Typography>
                                         
                                         
                                </Box>

                            </Box>
                      
                  </Box>
      </Box> */}
      <Box sx={{
        width:"100vw",
        height:"5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#141527"
      }}>
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "center",
            fontSize: "1.084rem",
            fontFamily: "Jost",
            color: "#fff",
            padding: "0.5rem 0rem"
          }}
        >
          © 2022 S20.AI, Inc
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
