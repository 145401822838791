import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Navbar from "../components/Navbar";
import Data from "../Data/Landing";
import TypographyLinkButton from '../components/TypographyLinkButton'
import Logo from '../components/Logo';
import '../assets/css/landing.css'

const Landing = () => {
  return (
    <Stack
      id="landing"
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: "rgb(169, 193, 255)",
      }}
    >
      {/* <Navbar /> */}
      <Stack sx={{ 
        height: "80%", 
        justifyContent: "center", 
        margin: "0rem 4rem",
        backgroundImage: "url('../assets/6bcameras.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "30%",
        backgroundPositionX: "100%",
        backgroundPositionY: "50%",
        padding: "3rem 0rem"

        }}
        className="landing-stack"
        >
        <Logo objectPosition={"left"}/>
        <Typography sx={{
          padding:"1rem 0rem"
        }}>
          {Data.title}
        </Typography>
        <TypographyLinkButton link={"mailto:origin@s20.ai"} text={"Request a demo"} bgColor={"#090A14"} color={"#fff"} />
        
      </Stack>
      <Stack
        sx={{
          height: "30vh",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%", height: "50%" }} /> {/* //!placeholder */}
        <Box sx={{ width: "100%", height: "50%", position: "relative" }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              minHeight: "10rem",
              padding: "2rem",
              width: "70%",
              bgcolor: "#141527",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {Data.caption}
          </Stack>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "white",
              marginLeft: "4rem",
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default Landing;
