import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Landing from "./screens/Landing";
import Product from "./screens/Product";
import Solutions from "./screens/Solutions";
import Features from "./screens/Features";
import Setup from "./screens/Setup";
import Testimonials from "./screens/Testimonials";
import News from "./screens/News";
import CallToAction from "./screens/CallToAction";
import Footer from "./screens/Footer";


const App = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Stack>
      <Landing />
      <Solutions />
      <Product />
      <Features />
      <Setup />
      {/* <Testimonials /> */}
      <News/>
      <CallToAction />
      <Footer />
    </Stack>
  );
};

export default App;
