import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import FeatureListItem from "../components/FeatureListItem";

import Data from "../Data/Features";

const generateFeatureCards = () =>
  Data.featuresArray.map((feature) => (
    <FeatureListItem {...feature} key={feature.title} />
  ));

const Features = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "50vh",
        paddingTop:"3rem"
      }}
      id="features"
    >
      <Stack
        alignItems="flex-start"
        justifyContent="space-evenly"
        sx={{
          marginLeft: "4rem",
          width: "100vw",
          height:"100%",
          bgcolor: "#fff",
          paddingLeft: "5rem",
          paddingTop: "3rem",
          paddingBottom: "3rem"
        }}
      >
        <Box sx={{ width: "30%", height: "0.3rem", bgcolor: "#141527" }} />
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "1.8rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#141527",
            margin: "1rem 0",
          }}
        >
          Features
        </Typography>
        <Box
          sx={{
            height: "80%",
            width: "100%",
            overflowX: "auto",
            display: "flex",
          }}
        >
          {generateFeatureCards()}
        </Box>
      </Stack>
    </Box>
  );
};

export default Features;
