import React from "react";
import { Button, Typography } from "@mui/material";

const TypographyLinkButton = ({link, text, bgColor, color}) => {
  return (
      <a href={link} target="_blank">
        <Button
            sx={{
              textTransform: "none",
              marginTop: "1rem",
              borderRadius: "0",
              bgcolor: bgColor,
              width: "10rem",
              height: "2.7rem",
              "&:hover": { bgcolor: bgColor },
            }}
          >
            <Typography
              sx={{
                textTransform: "none",
                fontSize: "0.9rem",
                fontFamily: "Jost",
                fontWeight: "500",
                padding: "0.5rem",
                color: color,
              }}
            >
              {text}
            </Typography>
          </Button>
      </a>
    
  );
};

export default TypographyLinkButton;
