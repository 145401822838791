import React from "react";


const Logo = ({objectPosition}) => {
  return (
    <img
      src="./assets/s20_logo.png"
      alt="s20-logo"
      style={{
        display: "block",
        width: "8rem",
        objectFit: "contain",
        objectPosition: objectPosition,
      }}
    />
  );
};

export default Logo;
