// eslint-disable-next-line import/no-anonymous-default-export
export default {
  productsArray: [
    {
      src: "../assets/app_builder.webp",
      altText: 'Vision AI App builder',
      title: "Vision AI App Builder",
      caption: "A low-code computer vision application builder",
    },
    {
      src: "../assets/vision_sop_capture.jpeg",
      altText: 'Visual SOP capture tool',
      title: "Visual SOP Capture Tool",
      caption: "A mobile application for robust data capture for various vision SOPs",
    },
    {
      src: "../assets/mlops_visual.svg",
      altText: 'ML Ops Platform',
      title: "ML Ops Platform",
      caption: "A full-fledged platform for productionising ML models",
    },
    {
      src: "../assets/vision_sdk.png",
      altText: '360 Vision SDK',
      title: "360 Vision SDK",
      caption: "A fully-configurable web SDK to view 360 captures",
    }
  ],
};
