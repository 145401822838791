import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ProductListItem from "../components/ProductListItem";

import Data from "../Data/Product";
import '../assets/css/product.css'

const generateProductCards = () =>
  Data.productsArray.map((product) => (
    <ProductListItem {...product} key={product.title} />
  ));

const Product = () => {
  return (
    <Box
    sx={{
      width: "100vw",
      height: "60vh",
    }}
    id="products"
    >
       <Stack
        alignItems="flex-start"
        justifyContent="space-evenly"
        sx={{
          height:"100%",
          bgcolor: "#141527",
          paddingTop: "3rem",
          paddingBottom: "3rem",
          paddingLeft: "9rem",
          marginRight: "4rem",
          // marginTop: "6rem"
        }}
        className="product-stack"
      >
         <Box sx={{ width: "30%", height: "0.3rem", bgcolor: "#fff" }} />
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "1.8rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#fff",
            margin: "2rem 0",
          }}
        >
          Products
        </Typography>

        <Stack
          sx={{
            width: "100%",
            height: "60%",
          }}
          direction="row"
          className="product-card-stack"
        >
          {generateProductCards()}
        </Stack>
       
      </Stack>
     
    </Box>
  );
};

export default Product;
