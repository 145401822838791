import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import React from "react";
import TypographyLinkButton from '../components/TypographyLinkButton';
import Data from '../Data/CallToAction';
import '../assets/css/callToAction.css'

const CallToAction = () => {
  return (
    <Box
    sx={{
      padding: "3rem 0",
      background: "rgba(20, 21, 39, 0)",
      height: "50vh"
      
    }}
    id="call-to-action"
    >
       <Box sx={{
           width: "50%",
           height: "100%",
           margin: "auto",
           display: "flex",
           flexDirection: "column",
           justifyContent: "space-around"
       }}>
         <Typography
          sx={{
            textTransform: "none",
            textAlign: "center",
            paddingLeft: "1rem",
            fontSize: "1.5rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#fff",
            marginBottom: "0.3rem",
            marginTop: "0.5rem",
          }}
        >
         {Data.title}
        </Typography>
        <Stack
          direction="row"
          // divider={<Divider orientation="vertical" flexItem style={{
          //   borderColor: "rgba(255,255,244,1"
          // }}  />}
            justifyContent="center"
            alignItems="center"
            spacing={10}
            sx={{
              padding:"2rem 0rem"
            }}
            className="partners-image-box"
          >

          <img className="image-1" src={Data.imageSrc1} style={{ width: "20vw"}}/>
          <img className="image-2" src={Data.imageSrc2} style={{ width: "20vw"}}/>
        </Stack>
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "center",
            paddingLeft: "1rem",
            fontSize: "1.084rem",
            fontFamily: "Jost",
            color: "#fff",
            marginBottom: "0.3rem",
            marginTop: "0.5rem",
          }}
        >
          {Data.caption}
        </Typography>
        {/* <Typography
          sx={{
            textTransform: "none",
            width: "95%",
            paddingLeft: "1rem",
            textAlign: "center",
            fontSize: "0.9rem",
            lineHeight: "1.2",
            fontFamily: "Jost",
            fontWeight: "400",
            color: "#fff",
          }}
        >
          Contact us to learn how Safecam can help you <br/>improve safety & hygiene to grow your business
        </Typography> */}
        <Box sx={{
            display : "flex",
            justifyContent : "center"
        }}>
          <TypographyLinkButton link={Data.btnLink} text={Data.btnText} bgColor={"#fff"} color={"#090A14"} />
        </Box>
        
       </Box>
    </Box>
  );
};

export default CallToAction;
