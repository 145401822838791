import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import SetupListItem from "../components/SetupListItem";

import Data from "../Data/Setup";

const generateSetupCards = () =>
  Data.setupArray.map((setup, idx) => (

    <SetupListItem {...Object.assign({}, {...setup, "idx":idx})} key={setup.caption} />
  ));

const Setup = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "50vh",
        paddingTop: "3rem"
      }}
      id="setup"
    >
      <Stack
        alignItems="flex-start"
        justifyContent="space-evenly"
        sx={{
          height: "100%",
          bgcolor: "#fff",
          paddingLeft: "9rem",
          paddingTop: "3rem",
          paddingBottom: "3rem",
          bgcolor: "#141527",
          marginRight: "4rem"
        }}
        className="setup-stack"
      >
        <Box sx={{ width: "30%", height: "0.3rem", bgcolor: "#fff" }} />
        <Typography
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "1.8rem",
            fontFamily: "Jost",
            fontWeight: "600",
            color: "#fff",
            margin: "1rem 0",
          }}
        >
          Easy Setup
        </Typography>
        <Box
          sx={{
            height: "80%",
            width: "100%",
            overflowX: "auto",
            display: "flex",
          }}
          className="setup-boxes"
        >
         
           
          {generateSetupCards()}
        </Box>
      </Stack>
    </Box>
  );
};

export default Setup;
